import { CheckIcon } from "@heroicons/react/solid";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import React from "react";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
import PriceCardItem from "./elements/PriceCardItem";

const features = [
	"Kennenlern- und Beratungsgespräch",
	"Konzeptionsphase",
	"Drei Revisionen pro Logo-Entwurf",
	"Corporate Design Manual mit allen erbrachten Definitionen",
	"Hochauflösende und vektorbasierte Logo-Dateien",
];

function LogodesignPlans({ onClick, image, url }) {
	const tiers = [
		{
			name: "Logodesign Basic",
			price: 1080,
			description: "Wir gestalten Dir ein einfaches individuelles Logo.",
			includedFeatures: ["Zwei unterschiedliche Logoentwürfe", "Definition Farbwelten", "Definition Typography"],
		},
		{
			name: "Logodesign Advanced",
			price: 1620,
			description:
				"Wir machen eine ausführliche Zielgruppenanalyse und gestalten Dein Logo anhand dieses Ergebnisses. Zusätzlich erstellen wir einmal passend dazu Deine Geschäftsausstattung.",
			includedFeatures: [
				"Zwei unterschiedliche Logoentwürfe",
				"Zielgruppenanalyse",
				"Definition Farbwelten",
				"Definition Typography",
				"Erstellung Geschäftsausstattung (Visitenkarten-Layout, Briefpapier-Layout, E-Mail-Signatur)",
			],
		},
	];

	const options = [
		{
			name: "2-seitiger Flyer inkl. Texterstellung",
			price: "360,- €",
			priceSub: "netto",
			description: (
				<>
					Wir gestalten Dir einen zweiseitigen Flyer in einer beliebigen Größe und erstellen zusätzlich die Texte dazu.
					Die Druckkosten sind nicht enthalten, da diese je nach Auflage und Druckergebnis stark variieren können.
				</>
			),
		},
		{
			name: "6-seitiger Flyer inkl. Texterstellung",
			price: "720,- €",
			priceSub: "netto",
			description: (
				<>
					Wir gestalten Dir einen sechseitigen Flyer in einer beliebigen Größe und erstellen zusätzlich die Texte dazu.
					Die Druckkosten sind nicht enthalten, da diese je nach Auflage und Druckergebnis stark variieren können.
				</>
			),
		},
	];

	return (
		<Container noPadding className="my-8">
			<SchemaProduct
				name="Logodesign"
				image={image}
				description="Logodesign Kassel. Konzeption, Design & Realisierung erfolgreicher Marken ▻ Gratis Beratung & Angebot!"
				aggregateOffer
				lowPrice="1080"
				highPrice="1620"
				url={url}
			/>
			<H2 display className="mb-5 font-display">
				Preise für Logodesign Kassel
			</H2>
			<Grid cols={2} className="mb-16" itemsCenter>
				<div>
					<P className="mb-3">
						Eine Logo-Gestaltung kann schnell je nach Anforderung sehr teuer werden. Die Problematik, die in Bezug auf
						die Kosten entsteht, ist, dass je nach Planung und Absprache ein starkes Defizit zwischen Angebot und
						tatsächlich aufgebrachten Stunden aufkommen kann. Deswegen haben wir uns zur Aufgabe gemacht unsere
						planbaren Stunden in Pakete zu verpacken und eher mit Dir und Deinem Business zu beschäftigen, als hunderte
						Entwürfe aus dem Ärmel zu schütteln. Wir möchten Dich genau kennenlernen und Deine Erwartungen evaluieren
						und unsere Empfehlungen aussprechen. Durch diese detaillierte Absprache kannst Du so mit Entwürfen zu einem
						Festpreis rechnen, die Dir wirklich gefallen.
					</P>
				</div>
			</Grid>

			<Grid cols={2} itemsStart id="preise">
				{tiers.map((tier) => (
					<PriceCardItem
						name={tier.name}
						description={tier.description}
						price={tier.price}
						standardFeature={
							<AnchorLink
								to="/leistungen/logodesign-kassel/#features"
								title="Alle Basis-Leistungen"
								className="underline"
							/>
						}
						features={tier.includedFeatures}
						onClick={onClick}
						key={tier.name}
					/>
				))}
			</Grid>
			<Grid cols={3} className="mt-16">
				<div id="features">
					<H3 className="mb-5 font-display" as="p">
						Von der Konzeption bis zum fertigen Logo: unsere Basis-Leistungen
					</H3>
					<P className="my-4">
						Bei uns gibt es keine halben Sachen. Mit dem Beauftragen des Logodesigns, erhältst Du bei uns ein
						Basis-Paket ohne Zusatzkosten, welches alle relevanten Dienstleistungen enthält, sodass Deine Zufriedenheit
						garantiert ist.
					</P>
				</div>
				<div className="col-span-2">
					<ul className="grid gap-3 sm:grid-cols-2">
						{features.map((feature) => (
							<li key={feature} className="flex py-4">
								<CheckIcon className="w-6 h-6 text-green-500 shrink-0" aria-hidden="true" />
								<span className="ml-3 text-sm">{feature}</span>
							</li>
						))}
					</ul>
				</div>
			</Grid>
			<Grid cols={3} className="mt-16">
				<div>
					<H3 className="mb-5 font-display" as="p">
						Passende Produkte zu Deinem Logo
					</H3>
					<P className="mt-4">
						Du möchtest einen Schritt weiter gehen und benötigst nicht nur ein Logo? Mit unseren Zusatzleistungen bilden
						wir die wichtigsten Produkte ab, die Du für einen ersten Markenauftritt benötigst.
					</P>
				</div>
				<div className="md:col-span-2">
					<ul className="grid items-start grid-cols-1 gap-3 md:grid-cols-2">
						{options.map((option) => (
							<li className={`bg-pattern p-2 ${option.span === 2 ? "md:col-span-2" : ""}`} key={option.name}>
								<div className="p-3 bg-white">
									<H3 as="p">{option.name}</H3>
									<P className="mt-4 text-sm" style={{ minHeight: "4rem" }}>
										{option.description}
									</P>
									<P className="my-4">
										<span className="text-2xl font-bold leading-none">
											{option.price}
											<span className="text-sm font-normal text-gray-700">
												{option.priceSub && <> / {option.priceSub}</>}
											</span>
										</span>
									</P>
								</div>
							</li>
						))}
					</ul>
				</div>
			</Grid>
		</Container>
	);
}

export default LogodesignPlans;

LogodesignPlans.propTypes = {
	onClick: PropTypes.func.isRequired,
	image: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
};
