import { ChevronRightIcon } from "@heroicons/react/outline";
import { graphql, navigate } from "gatsby";
import React from "react";
import BlurbSphere from "~components/features/BlurbSphere";
import Container from "~components/common/Container";
import FaqItem from "~components/features/FaqItem";
import Grid from "~components/common/Grid";
import Layout from "~components/layouts/Layout";
import CtaSection from "~components/sections/Cta";
import LogodesignPlans from "~components/sections/PricingPlans/LogodesignPlans";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import About from "~components/sections/landingpages/About";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

const faq = [
	{
		title: "Wie viele Entwürfe sind im Logodesign inbegriffen?",
		text: "In unseren Standardpaketen sind immer zwei Entwürfe geplant.",
	},
	{
		title: "Was ist, wenn mir das Logodesign nicht gefällt?",
		text: "Deine Zufriedenheit steht immer an vorderster Stelle. Sollten Dir die Entwürfe grundsätzlich nicht gefallen, dann gab es ein Problem in der Kommunikation zwischen Dir und uns. In diesem Fall versuchen wir gemeinsam mit Dir nochmal zu evaluieren, was Deine Vorstellungen sind und wohin die Reise gehen soll und erstellen einen weiteren kostenlosen Entwurf. Alle darauffolgenden Entwürfe kosten zusätzliche 360,- €.",
	},
	{
		title: "Wofür kann ich mein Logodesign einsetzen?",
		text: "Dein Logodesign begleitet Sie auf allen Werbeelementen Deiner Unternehmung. Du kannst das Logo auf allen Dir erdenklichen Medien verwenden.",
	},
	{
		title: "Welche Schritte kommen nach dem Logodesign?",
		text: "Das Logo ist meistens nur der erste Schritt. Wünschst Du eine weitere ganzheitliche Betreuung, dann begleiten wir gerne Deinen weiteren Weg. Wir erstellen Dir gerne eine Website oder entwickeln digitale Lösungen. Auch in den Bereichen online Marketing können wir Dich unterstützen. Im Laufe der Zeit ist es auch wichtig Deine Corporate Identity auszuarbeiten. Das sind Richtlinien die über das einfache Design Deines Logos hinaus gehen und definieren, welche Werte Dein Unternehmen vertritt.",
	},
];

const keyword = "Logodesign Kassel";
const title = "Logodesign Kassel ↗️ Kostenlose Beratung und feste Preise";
const description =
	"Logodesign Kassel | Konzeption, Design & Realisierung erfolgreicher Marken ▻ ☎️ 0156 783 723 78 Gratis Beratung & Angebot!";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name="Logodesign"
				image={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				description="Logodesign Kassel. Konzeption, Design & Realisierung erfolgreicher Marken ▻ Gratis Beratung & Angebot!"
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>

			<LandingpageHeader
				headline={{ one: "Logodesign Kassel:", two: "Konzeption, Design & Realisierung erfolgreicher Marken" }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Logodesigner an der Arbeit` }}
				text={
					<>
						Es gibt keine zweite Chance für den ersten Eindruck. Wir entwickeln Dein <strong>Logodesign</strong> von
						Grund auf, oder geben Dir einen neuen frischen Look. Wir gestalten nicht nur ein Logo, sondern erschaffen{" "}
						<strong>Wiedererkennungswert</strong>.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<KeySellingpoints
				headline="Wie Dein Logo zum Erfolg beiträgt"
				intro="Dein Logo ist meist der erste Berührungspunkt Deiner Kunden mit der Marke und sollte Individualität ausdrücken. Ein durchdachtes Logo sollte einen guten ersten Eindruck machen und Deine Business-DNA widerspiegeln. Wie größer Dein Business wächst umso öfter wirst Du und Dein Logo wahrgenommen, deshalb ist es unabdingbar gleich von Anfang an ein individuelles und einzigartiges Logo zu gestalten, welches zukunftssicher und überall anwendbar ist. Wir betreuen unsere Kunden im Bezug auf Konzeption, Design & Realisierung von erfolgreicher Marken"
				sellingponts={[
					{
						headline: "Redesign oder Relaunch",
						text: [
							"Du hast schon ein Logo für Dein Unternehmen oder Dein Produkt, aber es ist ganz schön in die Jahre gekommen? Dann kann ein Redesign oder ein Relaunch Deines Logos infrage kommen. Auch die größten Unternehmen wie Coca-Cola, Apple oder Volkswagen haben ihr Logo irgendwann nochmal überdacht und modernisiert. Ein Grund dafür sind meist die neuen Anwendungsbereiche oder neue Werte, die das alte Logo gar nicht oder nur teilweise verkörpern. Das alte Logo hat eine gewisse Wiedererkennung. Damit dieses nicht verloren geht, schlägt ein guter Logo-Designer eine Brücke zwischen etablierter Bildmarke und neuem frischen Design. So erkennt Deine Zielgruppe das alte Logo in einem modernen und funktionellen Look wieder.",
						],
						image: data.logoRedesign,
						alt: `${keyword} - Redsign und Relaunch der aktuellen Marken`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Redesign / Relaunch anfragen",
						},
					},
					{
						headline: "Was ist eine Vektordatei?",
						text: [
							"Du kennst bestimmt die üblichen Dateiformate wie JPG oder PNG? Dein Logo verwendest Du in einem der beiden Formate? Dann kann es passieren, dass Du Deinem Logo nicht ganz gerecht wirst und zu Unschärfeeffekten führen kann. Der Grund dafür sind die technischen Pixel, die Dein Logo repräsentieren. Rasterlogos sind in ihrer Größe begrenzt, da Sie auf eine bestimmte Pixelgröße gestaltet wurden. Diese können dann gerne runter skaliert werden und bleiben zum größten Teil scharf. Problematisch wird es nun, wenn man das Logo hoch skalieren möchte. Durch die begrenzte Anzahl der Pixel wird das Logo unscharf.",
							"Abhilfe schaffen Vektordateien. Statt Pixel werden die Logos durch Vektoren repräsentiert. So werden Linien und Flächen berechnet und nicht als feste Pixel definiert, sondern als grafische Primitiven. So kann jeder Computer mit dem richtigen Programm Dein Logo verlustfrei skalieren. Das bedeutet konkret, dass ein Vektorlogo immer extrem scharf bleibt, egal in welche Größe es dargestellt werden muss. So können Designer das Logo theoretisch auch auf der Fläche eines Flugzeuges platzieren und das Logo bleibt unverändert scharf.",
							"Vektordateien sind also für eine professionelle Logogestaltung unabdingbar. Bei uns erhältst Du also in jedem Fall immer neben den normalen Pixelbildern auch die fertigen Vektordateien geliefert. So kannst Du sicher sein, dass Dein Logo zukunftssicher bleibt und auf allen Medien verlustfrei angewendet werden kann.",
						],
						image: data.logoVector,
						alt: `${keyword} - Vektordatei wir auf Grafiktablet angefertigt`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Vektordatei anfragen",
						},
					},
					{
						headline: "Datenübergabe",
						text: [
							"Nach Abschluss Deines Projekts erhältst Du von uns saubere Vektordateien, die für den Druck und für das Web geeignet sind. Zusätzlich erhältst Du natürlich auch für den üblichen Gebrauch alle Deine Logovariationen als normale PDF, JPG und PNG Dateien. Du erwirbst alle Nutzungsrechte an dem Logo und kannst sofort damit loslegen. Für den weiteren Gebrauch erhältst Du von uns auch ein Corporate Design Manual. Das ist meist eine PDF-Datei in der die Verwendung des Logos, die Schrift und die Farben für den Druck und für digitale Medien definiert ist. So hast Du immer etwas zur Hand, solltest Du planen Dein Logo in professionelle Hände zu geben.",
						],
						image: data.logoDaten,
						alt: `${keyword} - Datenübergabe`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Corporate Design Manual anfragen",
						},
					},
				]}
			/>

			<Container>
				<Grid cols={2}>
					<div className="mb-8 md:mb-32">
						<H2 display className="mb-5 font-display">
							Unser Ablauf
						</H2>
						<P>
							Ein erprobter Ablauf ist essenziell für ein erfolgreiches Ergebnis. Damit auch Du Bescheid weißt, wie wir
							vorgehen, findest Du anbei eine Auflistung wie unser Ablauf für ein Logodesign aussieht.
						</P>
					</div>
				</Grid>
				<Grid cols={3}>
					<BlurbSphere
						number="1"
						title="Aufklärungsgespräch"
						text="Wir möchten Dich und Dein Unternehmen kennenlernen. In einem Erstgespräch evaluieren wir Deine Vorstellungen und Ziele, nicht nur in Bezug auf das Logo, sondern auf Deine unternehmerischen Tätigkeiten. "
						svg={<ChevronRightIcon />}
					/>
					<BlurbSphere
						number="2"
						title="Konzeption"
						text="Anhand des Aufklärungsgesprächs machen wir uns auf und recherchieren und konzeptionieren welche Möglichkeiten wir haben um Deinen Ziele bestmöglich in einem Logo zu visualisieren."
						svg={<ChevronRightIcon />}
					/>
					<BlurbSphere
						number="3"
						title="Skizzierung"
						text="Anhand der von uns gesammelten Informationen fangen wir an Skizzen zu erstellen und evaluieren, welche Optionen wir für die Beste halten. Darauffolgend fangen wir an die ersten Logos zu gestalten und definieren die ersten Formen, Farben und Schriften."
						svg={<ChevronRightIcon />}
					/>
					<BlurbSphere
						number="4"
						title="Präsentation"
						text="Wir präsentieren Dir unsere persönlichen Favoriten und erklären Dir ausführlich unsere Gedanken dazu. Du erhältst eine klare Empfehlung, welche Logo-Variante aus unserer Sicht Deine Marke am stärksten repräsentiert."
						svg={<ChevronRightIcon />}
					/>
					<BlurbSphere
						number="5"
						title="Reinzeichnung"
						text="Das von Dir gewählte Logo wird nun komplett ausgearbeitet und für verschiedene Medien optimiert. Du erhältst von uns alle Daten in der finalen Form und erhältst zusätzlich eine Corporate Design Manual mit dem von uns definierten Designrichtlinien."
						svg={<ChevronRightIcon />}
					/>
				</Grid>
			</Container>
			<CtaSection
				title="Logodesign Kassel anfragen"
				text="Du bist überzeugt? Dann Fülle stelle uns gerne eine unverbindliche Anfrage und wir evaluieren wie wir Dir helfen können."
				button="Logodesign anfragen"
				onClick={() => navigate("/leistungen/logodesign-kassel/anfragen/")}
			/>

			<Container>
				<Grid cols={2} className="mb-8" itemsCenter>
					<div>
						<H2 display className="mb-5 font-display">
							Häufige Fragen
						</H2>
						<P>
							Du hast noch eine Frage? Wir haben hier die häufigsten Fragen zusammengetragen und kurz beantwortet.
							Solltest Du trotzdem noch eine andere Frage haben, dann kontaktiere uns gerne dazu!
						</P>
					</div>
				</Grid>
				<Grid cols={2} itemsStart>
					{faq.map((entry) => (
						<dl key={entry.title}>
							<FaqItem title={entry.title} text={entry.text} />
						</dl>
					))}
				</Grid>
			</Container>
			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(relativePath: { eq: "pages/leistungen/logodesign-kassel/logodesign-kassel_skizzierung.jpeg" }) {
			header: childImageSharp {
				gatsbyImageData
			}
		}

		seoImage: file(relativePath: { eq: "pages/leistungen/logodesign-kassel/logodesign-kassel_skizzierung.jpeg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}

		logoRedesign: file(relativePath: { eq: "pages/leistungen/logodesign-kassel/logodesign-kassel_logo-redesign.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		logoVector: file(relativePath: { eq: "pages/leistungen/logodesign-kassel/logodesign-kassel_vektor.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		logoDaten: file(relativePath: { eq: "pages/leistungen/logodesign-kassel/logodesign-kassel_daten.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
